<template>
  <div>
    <b-card>
      <usuariosList />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import usuariosList from './components/UsuariosList.vue'

// import usuariosList from './components/InquilinosList.vue'

export default {
  components: {
    BCard,
    usuariosList,
  },
  computed: {
    // ...mapGetters({ usuarios }),
  },
  created() {
    // Consultar autenticación del
  },
}
</script>

<style>

</style>
