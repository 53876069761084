<template>
  <b-row>
    <b-col
      lg="2"
      md="3"
      sm="3"
      class="my-1"
    >
      <!-- BOTON MOSTRAR -->
      <btnMostrar
        :pageOptions.sync="pageOptions"
        :perPage.sync="perPage"
        :total.sync="getUsuarios.length"
      />
    </b-col>
    <b-col
      lg="1"
      md="1"
      sm="1"
      class="my-1"
    >
    </b-col>
    <b-col
      lg="6"
      md="5"
      sm="5"
      class="my-1"
    >
      <!-- FILTRO -->
      <inputFiltro
        :filter.sync="filter"
      />
    </b-col>
    <b-col
      md="3"
      sm="3"
      class="my-1"
    >
      <!-- BOTON CREAR -->
      <btnCrear
        texto="Usuario"
        modulo="usuarios"
        @processAdd="addUsuario"
      />
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="getUsuarios"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :busy="cargando"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- Cargando -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <spinner />
          </div>
        </template>

        <!-- Column: Usuario -->
        <template #cell(nombres)="data">
          <colNombreImg
            :mostrarImg="true"
            :imagen="data.item.avatar"
            :nombre="`${data.item.nombres}
              ${data.item.primerApellido} ${data.item.segundoApellido}`"
            :txtMuted="data.item.email"
            :nombreModal="null"
          />
        </template>

        <!-- Column: Rol -->
        <template #cell(nombreRol)="data">
          <colRol
            :data="data.item"
          />
        </template>

        <!-- COLUMNA ESTABLECIMIENTO -->
        <template #cell(nombreEstablecimiento)="data">
          <div v-if="data.item.nombreEstablecimiento">
            {{ data.item.nombreEstablecimiento }}
          </div>
          <div v-else>
            TODOS
          </div>
        </template>

        <!-- COLUMNA ESTADO -->
        <template #cell(estado)="data">
          <colEstado
            :data="data"
            modulo="usuarios"
            @processUpdateEstado="updateEstado"
          />
        </template>

        <!-- Column: Action -->
        <template #cell(acciones)="data">
          <colAccionesBtnes
            modulo="usuarios"
            :data="data"
            @processGoToUpdate="goToUpdate"
            @processRemove="remove(data.item)"
          />
          <!-- :estado="data.item.destroy.estado"
          :cantidad="data.item.destroy.cantidad" -->
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import spinner from '../../components/spinner.vue'
import colAccionesBtnes from '../../components/List/colAccionesBtnes.vue'
import btnCrear from '../../components/List/btnCrear.vue'
import inputFiltro from '../../components/List/inputFiltro.vue'
import btnMostrar from '../../components/List/btnMostrar.vue'
import colEstado from '../../components/List/colEstado.vue'
import colRol from '../../components/List/colRol.vue'
import colNombreImg from '../../components/List/colNombreImg.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,

    spinner,
    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    colEstado,
    colRol,
    colNombreImg,
  },
  data() {
    return {
      cargando: true,
      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombres',
          label: 'Nombres',
          sortable: true,
          thStyle: {
            width: '250px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'rut',
          label: 'Rut',
          sortable: false,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'email',
        //   label: 'Correo',
        //   sortable: false,
        //   thStyle: {
        //     width: '200px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'nombreRol',
          label: 'Rol',
          sortable: false,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreEstablecimiento',
          label: 'Establecimiento',
          sortable: true,
          thStyle: {
            width: '300px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'idUsuarioCreated',
        //   label: 'Apellido Materno',
        //   sortable: true,
        //   thStyle: {
        //     width: '200px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getUsuarios: 'usuarios/getUsuarios' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getUsuarios(usuarios) {
      this.totalRows = usuarios.length
      this.cargando = false
    },
  },
  mounted() {
    this.fetchUsuarios()
    this.setTableList()
  },
  methods: {
    ...mapActions({
      fetchUsuarios: 'usuarios/fetchUsuarios',
      updateUsuarioEstado: 'usuarios/updateUsuarioEstado',
      removeUsuarios: 'usuarios/removeUsuarios',
    }),
    ...mapMutations('usuarios', ['setUsuario']),
    setTableList() {
      if (this.$can('update', 'establecimientos')
        || this.$can('delete', 'establecimientos')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    addUsuario() {
      this.$router.replace({
        name: 'usuarios-create',
      })
    },
    updateEstado(usuario) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${usuario.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateUsuarioEstado(usuario)
          this.$swal({
            icon: 'success',
            title: 'Estado actualizado!',
            text: `Se ha actualizado el estado de "${usuario.nombre}"!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    goToUpdate(usuario) {
      this.setUsuario(usuario)
      this.$router.push({
        name: 'usuarios-update',
        params: { id: usuario.id },
      })
    },
    remove(usuario) {
      this.$swal({
        title: 'Eliminar usuario!',
        text: `Estás seguro que deseas eliminar la usuario "${usuario.nombres}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeUsuarios(usuario.id)
          this.$swal({
            icon: 'success',
            title: 'Eliminada con éxito!',
            text: `"${usuario.nombre}" ha sido eliminada!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
